import React from 'react'
import AdSense from 'react-adsense'

const AdBlock = () => (
    <>
        <AdSense.Google
            client="ca-pub-6343289823091285"
            slot="5114483936"
            style={{ display: 'block', width: "100%" }}
            layout="in-article"
            format="fluid"
        />
    </>
)

export default AdBlock
