import React, { createContext, useEffect, useReducer, useState } from 'react'
import Parse from '../vendor/Parse'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
const isAuth = () => {
    if (typeof window !== 'undefined') {
        const user = Parse.User.current();
        return user && user.toJSON() || null;
    } else {
        return null;
    }
}

const getUser = () => isAuth()

const getEnv = () => {
    if (typeof window !== 'undefined') {
        return window.location.hostname === 'localhost' ? "dev" : "prod";
    } else {
        return 'prod';
    }
}

const register = async (email, username, password, mobile) => {
    return await Parse.Cloud.run('createUser', {
        email,
        name: username,
        password,
        contact: mobile
    })
}

const AuthContext = createContext({
    isAuth,
    env: getEnv()
})

export const AuthProvider = ({ children }) => {

    const [isLogin, setIsLogin] = useState(isAuth())
    const [message, setMessage] = useState("")
    const logout = async () => {
        if (typeof window !== 'undefined') {
            await Parse.User.logOut();
            setIsLogin(isAuth())
        } else {
            return null;
        }
    }

    const login = async (username, password) => {
        if (typeof window !== 'undefined') {
            const user = await Parse.User.logIn(username, password)
            setIsLogin(isAuth())
        } else {
            return null;
        }
    }

    return (
        <AuthContext.Provider
            value={{
                isAuth,
                env: getEnv(),
                logout,
                isLogin,
                register,
                getUser,
                login,
                user: isLogin,
                setMessage
            }}
        >
            {children}
            {
                 <Snackbar
                    ContentProps={{
                        sx: {
                            background: "red"
                        }
                    }}
                    open={message}
                    autoHideDuration={6000}
                    onClose={() => { setMessage("") }}
                    message={message}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}

                >
                </Snackbar>
            }
        </AuthContext.Provider>
    )
}

export default AuthContext
