import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Grass, GpsFixed, Password, RepeatOne, Login, SixtyFps, Assignment, Cloud, Inventory2, Filter9, VerifiedUser, SendToMobile, ManageSearch, Gavel, WhatsApp, Home } from '@mui/icons-material';
import { navigate } from "gatsby"
import Ad from '../components/Ad'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useAuth from "../hooks/userAuth";
import { useState, useEffect } from 'react'

export default function Menu({ children }) {
    const { isAuth, getEnv, logout, user } = useAuth()
    const authenticated = isAuth()
    return (
        <>
            <MenuList>
                <h3>Home</h3>
                <MenuItem onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <Home fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Home</ListItemText>
                </MenuItem>
                <h3>TCM</h3>
                {
                    <>
                        <MenuItem onClick={() => navigate('/accupunture')}>
                            <ListItemIcon>
                                <GpsFixed fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>针灸 Accupunture</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => navigate('/herbal-formula')}>
                            <ListItemIcon>
                                <Grass fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>经方 Herbal Formula</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => navigate('/number-formula')}>
                            <ListItemIcon>
                                <SixtyFps fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>数字方 Number For Health</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => navigate('/find-tcm')}>
                            <ListItemIcon>
                                <RepeatOne fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Find TCM</ListItemText>
                        </MenuItem>
                    </>
                }
                <h3>Numerology</h3>

                <MenuItem onClick={() => navigate('/ask')}>
                    <ListItemIcon>
                        <Filter9 fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>五行数字 Five Element Number</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/mobile')}>
                    <ListItemIcon>
                        <SendToMobile fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>数字能量 Number Energy</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/findMobileNumber')}>
                    <ListItemIcon>
                        <RepeatOne fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Mobile Number Finding</ListItemText>
                </MenuItem>

                <h3>Metaphysics</h3>

                <h3>Account</h3>
                {
                    !authenticated && <MenuItem onClick={() => navigate('/login')}>
                        <ListItemIcon>
                            <Login fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Login</ListItemText>
                    </MenuItem>
                }
                {
                    authenticated && <>
                        <MenuItem onClick={() => navigate('/changePassword')}>
                            <ListItemIcon>
                                <Password fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Update Password</ListItemText>
                        </MenuItem>
                        {
                            user.role === 'admin' && <MenuItem onClick={() => navigate('/manage')}>
                                <ListItemIcon>
                                    <Password fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Manage</ListItemText>
                            </MenuItem>
                        }
                        <MenuItem onClick={() => logout()}>
                            <ListItemIcon>
                                <Login fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>

                    </>
                }

            </MenuList>
        </>
    );
}

